var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    'global-search-field': true,
    'global-search-field-open': _vm.isSearchActive
  }},[_c('span',{staticClass:"search-icon"},[_c('search-icon',{attrs:{"width":"20"}})],1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchQuery),expression:"searchQuery"}],ref:"global-search-field",staticClass:"input",attrs:{"placeholder":"ctrl+alt+f"},domProps:{"value":(_vm.searchQuery)},on:{"focus":function($event){_vm.isSearchActive = true},"blur":_vm.onBlur,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onElementSelected.apply(null, arguments)},"input":function($event){if($event.target.composing){ return; }_vm.searchQuery=$event.target.value}}}),(_vm.isSearchActive)?_c('div',{staticClass:"search-results",style:({
      'min-height': (_vm.nbResults * 60) + 'px'
    })},[(_vm.searchQuery.length < 3)?_c('div',{staticClass:"result-line"},[_vm._v(" "+_vm._s(_vm.$t('main.search.type'))+" ")]):(_vm.isLoading)?_c('div',{staticClass:"search-loader",style:({
        'min-height': (_vm.nbResults * 60) + 'px'
      })},[_c('div',[_c('spinner')],1)]):(_vm.nbResults > 0)?_c('div',[_vm._l((_vm.assets),function(asset,index){return _c('div',{key:asset.id,class:{
          'result-line': true,
          'selected-result': _vm.selectedIndex === index
        },on:{"click":_vm.onElementSelected}},[_c('router-link',{attrs:{"id":'result-link-' + index,"to":_vm.entityPath(asset)}},[_c('div',{staticClass:"flexrow",on:{"mouseover":function($event){_vm.selectedIndex = index}}},[_c('div',{staticClass:"flexrow-item"},[_c('entity-thumbnail',{staticStyle:{"margin-top":"5px"},attrs:{"empty-height":40,"empty-width":60,"height":40,"width":60,"entity":asset,"with-link":false}})],1),_c('div',{staticClass:"flexrow-item"},[_c('div',{staticClass:"production-name"},[_vm._v(" "+_vm._s(asset.project_name)+" ")]),_c('div',{staticClass:"asset-type-name"},[_vm._v(" "+_vm._s(asset.asset_type_name)+" / "+_vm._s(asset.name)+" ")])])])])],1)}),_vm._l((_vm.persons),function(person,index){return _c('div',{key:person.id,class:{
          'result-line': true,
          'selected-result': _vm.selectedIndex === index + _vm.assets.length
        },on:{"click":_vm.onElementSelected}},[_c('router-link',{attrs:{"id":'result-link-' + (index + _vm.assets.length),"to":_vm.personPath(person)}},[_c('div',{staticClass:"flexrow",on:{"mouseover":function($event){_vm.selectedIndex = index + _vm.assets.length}}},[_c('people-avatar',{staticClass:"flexrow-item",attrs:{"is-link":false,"person":person}}),_c('people-name',{staticClass:"flexrow-item",attrs:{"person":person}})],1)])],1)})],2):_c('div',{staticClass:"result-line"},[_vm._v(" "+_vm._s(_vm.$t('main.search.no_result'))+" ")])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }