var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
  'modal': true,
  'delete-modal': true,
  'is-active': _vm.active
}},[_c('div',{staticClass:"modal-background",on:{"click":function($event){return _vm.$emit('cancel')}}}),_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"box"},[_c('p',{staticClass:"text"},[_vm._v(_vm._s(_vm.text))]),_c('p',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userLockText),expression:"userLockText"}],ref:"confirmation-name",staticClass:"input",attrs:{"type":"text"},domProps:{"value":(_vm.userLockText)},on:{"input":function($event){if($event.target.composing){ return; }_vm.userLockText=$event.target.value}}})]),(_vm.isError)?_c('p',{staticClass:"is-danger"},[_vm._v(_vm._s(_vm.errorText))]):_vm._e(),_c('div',{staticClass:"has-text-right flexrow"},[_c('div',{staticClass:"filler"}),(_vm.selectionOption)?_c('combobox',{staticClass:"flexrow-item",attrs:{"options":_vm.selectionOptions,"with-margin":false},model:{value:(_vm.selectionOnly),callback:function ($$v) {_vm.selectionOnly=$$v},expression:"selectionOnly"}}):_vm._e(),_c('a',{class:{
            button: true,
            'is-danger': true,
            'is-loading': _vm.isLoading
          },attrs:{"disabled":_vm.isLocked},on:{"click":function($event){return _vm.$emit('confirm', _vm.selectionOnly === 'true')}}},[_vm._v(" "+_vm._s(_vm.$t("main.confirmation"))+" ")]),_c('button',{staticClass:"button is-link",on:{"click":function($event){return _vm.$emit('cancel')}}},[_vm._v(" "+_vm._s(_vm.$t('main.cancel'))+" ")])],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }