var render = function (_h,_vm) {var _c=_vm._c;return (_vm.props.previewFileId)?_c('img',_vm._b({directives:[{name:"lazy",rawName:"v-lazy",value:('/api/pictures/' + _vm.props.type + '/preview-files/' + _vm.props.previewFileId + '.png'),expression:"'/api/pictures/' + props.type + '/preview-files/' + props.previewFileId + '.png'"}],key:_vm.props.previewFileId,staticClass:"thumbnail-picture",style:({
    width: _vm.props.width,
    height: _vm.props.height,
    'max-width': _vm.props.maxWidth,
    'max-height': _vm.props.maxHeight
  })},'img',_vm.data.attrs,false)):_c('span',_vm._b({class:{
    'thumbnail-picture': true,
    'thumbnail-empty': true
  },style:({
    width: _vm.props.width,
    height: _vm.props.emptyHeight ? _vm.props.emptyHeight : _vm.props.height,
  })},'span',_vm.data.attrs,false))}
var staticRenderFns = []

export { render, staticRenderFns }