import { render, staticRenderFns } from "./TaskList.vue?vue&type=template&id=0221e552&scoped=true&"
import script from "./TaskList.vue?vue&type=script&lang=js&"
export * from "./TaskList.vue?vue&type=script&lang=js&"
import style0 from "./TaskList.vue?vue&type=style&index=0&id=0221e552&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0221e552",
  null
  
)

export default component.exports