var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    'modal': true,
    'is-active': _vm.active
  },attrs:{"id":"add-comment-modal"}},[_c('div',{staticClass:"modal-background",on:{"click":function($event){return _vm.$emit('cancel')}}}),_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"box content"},[_c('h2',{staticClass:"subtitle"},[_vm._v(_vm._s(_vm.title))]),(_vm.isEditing)?_c('h1',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t("tasks.change_preview"))+" ")]):_c('h1',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t("tasks.add_preview"))+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.$t("tasks.select_preview_file"))+" ")]),_c('file-upload',{ref:"preview-field",attrs:{"accept":_vm.extensions,"multiple":true,"label":'Select files from your hard drive',"is-primary":false,"hide-file-names":""},on:{"fileselected":_vm.onFileSelected}}),(_vm.isError)?_c('p',{staticClass:"error"},[_vm._v(" "+_vm._s(_vm.$t("tasks.add_preview_error"))+" ")]):_vm._e(),(_vm.forms.length > 0)?_c('h3',{staticClass:"subtitle has-text-centered"},[_vm._v(" Selected Files ")]):_vm._e(),(_vm.forms.length > 0)?_c('p',{staticClass:"upload-previews mt2"},[_vm._l((_vm.forms),function(form,i){return [_c('p',{key:'name-' + i,staticClass:"preview-name"},[_vm._v(" "+_vm._s(form.get('file').name)+" "),_c('span',{on:{"click":function($event){return _vm.removePreview(form)}}},[_vm._v("x")])]),(_vm.isImage(form))?_c('img',{key:i,attrs:{"alt":"uploaded file","src":_vm.getURL(form)}}):(_vm.isVideo(form))?_c('video',{key:i,staticClass:"is-fullwidth",attrs:{"preload":"auto","autoplay":"","controls":"","loop":"","muted":"","src":_vm.getURL(form)},domProps:{"muted":true}}):(_vm.isPdf(form))?_c('iframe',{key:i,staticClass:"is-fullwidth",attrs:{"frameborder":"0","src":_vm.getURL(form)}}):_vm._e(),_c('hr',{key:'separator-' + i})]})],2):_vm._e(),_c('p',{staticClass:"has-text-right"},[_c('a',{class:{
            button: true,
            'is-primary': true,
            'is-loading': _vm.isLoading,
            'is-disabled': _vm.forms.length === 0
          },on:{"click":function($event){return _vm.$emit('confirm')}}},[_vm._v(" "+_vm._s(_vm.$t("tasks.add_revision_confirm"))+" ")]),_c('button',{staticClass:"button is-link",on:{"click":function($event){return _vm.$emit('cancel')}}},[_vm._v(" "+_vm._s(_vm.$t("main.cancel"))+" ")])])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }