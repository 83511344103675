var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"production-name flexrow"},[(_vm.withAvatar)?_c('div',{staticClass:"flexrow-item avatar has-text-centered",style:({
       background: _vm.getAvatarColor(_vm.production),
       width: _vm.size + 'px',
       height: _vm.size + 'px',
       'font-size': (_vm.size - 15) + 'px',
       'line-height': _vm.size + 'px'
     })},[(!_vm.production.has_avatar)?_c('span',[_vm._v(" "+_vm._s(_vm.generateAvatar(_vm.production))+" ")]):_c('img',{style:({
        width: _vm.size + 'px',
        height: _vm.size + 'px'
      }),attrs:{"src":_vm.getThumbnailPath(_vm.production)}})]):_vm._e(),(!_vm.onlyAvatar)?_c('span',{staticClass:"flexrow-item avatar-name"},[_vm._v(" "+_vm._s(_vm.production.name)+" ")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }