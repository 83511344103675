var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isPreview && _vm.withLink)?_c('a',{staticClass:"thumbnail-wrapper thumbnail-picture",style:({
    width: _vm.emptyWidth + 'px',
    'min-width': _vm.emptyWidth + 'px',
    height: _vm.emptyHeight + 'px',
  }),attrs:{"target":"_blank"},on:{"click":_vm.onClicked}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.thumbnailPath),expression:"thumbnailPath"}],key:_vm.thumbnailKey,staticClass:"thumbnail-picture",style:(_vm.imgStyle),attrs:{"width":_vm.width || ''}})]):(_vm.isPreview && !_vm.withLink)?_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.thumbnailPath),expression:"thumbnailPath"}],key:_vm.thumbnailKey,staticClass:"thumbnail-picture",staticStyle:{},attrs:{"width":_vm.width || ''}}):_c('span',{class:{
    'thumbnail-picture': true,
    'thumbnail-empty': true,
    square: _vm.square
  },style:({
    width: _vm.emptyWidth + 'px',
    height: _vm.emptyHeight + 'px',
  })})}
var staticRenderFns = []

export { render, staticRenderFns }