var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-autocomplete',{ref:"autocomplete",attrs:{"auto-select-one-item":false,"component-item":_vm.assignationItem,"get-label":_vm.getAssignationLabel,"items":_vm.items,"input-attrs":{
    placeholder: _vm.placeholder || this.$t('people.select_person'),
    class: _vm.wide
      ? 'big wide v-autocomplete-input'
      : _vm.big
        ? 'big v-autocomplete-input'
        : 'v-autocomplete-input'
  },"min-len":1},on:{"update-items":_vm.update,"input":_vm.onChange},model:{value:(_vm.item),callback:function ($$v) {_vm.item=$$v},expression:"item"}})}
var staticRenderFns = []

export { render, staticRenderFns }