var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"unselectable",on:{"mouseenter":function($event){_vm.isFrameNumberVisible = true},"mouseout":function($event){_vm.isFrameNumberVisible = false}}},[_c('div',{staticClass:"progress-wrapper",style:({
      'background-size': _vm.backgroundSize
    })},[(_vm.handleIn >= 0)?_c('span',{staticClass:"handle-in",style:({
        width: _vm.handleInWidth,
        'padding-right': _vm.handleIn > 1 ? '5px' : 0
      }),on:{"mousedown":function($event){return _vm.startHandleInDrag($event)}}},[_vm._v(" "+_vm._s(_vm.handleIn !== 0 ? _vm.handleIn + 1 : '')+" ")]):_vm._e(),(_vm.handleOut >= 0)?_c('span',{staticClass:"handle-out",style:({
        width: _vm.frameSize * (_vm.nbFrames - _vm.handleOut) + 'px'
      }),on:{"mousedown":function($event){return _vm.startHandleOutDrag($event)}}},[_vm._v(" "+_vm._s(_vm.handleOut + 1)+" ")]):_vm._e(),_c('progress',{ref:"progress",attrs:{"value":"0","min":"0"},on:{"click":_vm.onProgressClicked,"mousedown":function($event){return _vm.startProgressDrag($event)}}}),_vm._l((_vm.annotations),function(annotation,index){return _c('span',{key:("annotation-" + index),staticClass:"annotation-mark",style:({
        left: _vm.getAnnotationPosition(annotation) + 'px',
        width: Math.max(_vm.frameSize - 1, 5) + 'px'
      }),on:{"mouseenter":function($event){_vm.isFrameNumberVisible = true},"mouseleave":function($event){_vm.isFrameNumberVisible = true},"click":function($event){return _vm._emitProgressEvent(annotation)}}})}),_c('span',{staticClass:"frame-number",style:({
        display: _vm.isFrameNumberVisible ? null : 'none',
        left: _vm.frameNumberLeftPosition + 'px'
      })},[_vm._v(" "+_vm._s(_vm.hoverFrame)+" ")])],2)])}
var staticRenderFns = []

export { render, staticRenderFns }