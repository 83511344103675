var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
  'modal': true,
  'is-active': _vm.active
}},[_c('div',{staticClass:"modal-background",on:{"click":function($event){return _vm.$emit('cancel')}}}),_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"box"},[_c('h1',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t('playlists.select_task_type'))+" ")]),_c('form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('combobox-task-type',{attrs:{"task-type-list":_vm.taskTypeList,"value":_vm.taskTypeId},on:{"input":_vm.onTaskTypeChanged}})],1),_c('p',[_vm._v(" "+_vm._s(_vm.$t('playlists.apply_task_type_change'))+" ")]),_c('p',{staticClass:"has-text-right mt2"},[_c('a',{class:{
            button: true,
            'is-primary': true,
            'is-loading': _vm.isLoading
          },on:{"click":_vm.runConfirmation}},[_vm._v(" "+_vm._s(_vm.$t("main.confirmation"))+" ")]),_c('button',{staticClass:"button is-link",on:{"click":function($event){return _vm.$emit('cancel')}}},[_vm._v(" "+_vm._s(_vm.$t("main.cancel"))+" ")])]),(_vm.isError)?_c('p',{staticClass:"error has-text-right info-message"},[_vm._v(" "+_vm._s(_vm.$t("playlist.change_task_type_fails"))+" ")]):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }