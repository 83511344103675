var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
  'modal': true,
  'is-active': _vm.active
}},[_c('div',{staticClass:"modal-background",on:{"click":function($event){return _vm.$emit('cancel')}}}),_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"box"},[_c('page-title',{staticClass:"title",attrs:{"text":_vm.$t('shots.manage')}}),_c('div',{staticClass:"explaination"},[_vm._v(_vm._s(_vm.$t('shots.creation_explaination')))]),_c('div',[_c('div',{staticClass:"flexrow"},[_c('combobox',{ref:"shot-padding",staticClass:"shot-padding flexrow-item",attrs:{"label":_vm.$t('shots.padding'),"options":_vm.shotPaddingOptions},model:{value:(_vm.shotPadding),callback:function ($$v) {_vm.shotPadding=$$v},expression:"shotPadding"}})],1)]),_c('div',{staticClass:"shot-columns"},[(_vm.isTVShow)?_c('div',{staticClass:"shot-column"},[_c('h2',{staticClass:"subtitle"},[_vm._v(_vm._s(_vm.$t('shots.episodes')))]),_c('div',{staticClass:"list"},_vm._l((_vm.episodes),function(episode){return _c('div',{key:episode.id,class:{
                'entity-line': true,
                selected: episode.id === _vm.selectedEpisodeId
              },on:{"click":function($event){return _vm.selectEpisode(episode.id)}}},[_vm._v(" "+_vm._s(episode.name)+" ")])}),0),_c('div',{staticClass:"field"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.names.episode),expression:"names.episode"},{name:"focus",rawName:"v-focus"}],ref:"addEpisodeInput",staticClass:"input",attrs:{"placeholder":"EP01","type":"text"},domProps:{"value":(_vm.names.episode)},on:{"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab")){ return null; }return _vm.focusAddSequence.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.addEpisode.apply(null, arguments)}],"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.names, "episode", $event.target.value)}}}),_c('button',{class:{
                button: true,
                'is-success': true,
                'is-loading': _vm.loading.addEpisode
              },attrs:{"disabled":!_vm.isAddEpisodeAllowed},on:{"click":_vm.addEpisode}},[_vm._v(" "+_vm._s(_vm.$t('main.add'))+" ")])])]):_vm._e(),_c('div',{staticClass:"shot-column"},[_c('h2',{staticClass:"subtitle"},[_vm._v(_vm._s(_vm.$t('shots.sequences')))]),_c('div',{staticClass:"list"},_vm._l((_vm.displayedSequences),function(sequence){return _c('div',{key:sequence.id,class:{
                'entity-line': true,
                selected: sequence.id === _vm.selectedSequenceId
              },on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab")){ return null; }return _vm.focusAddShot.apply(null, arguments)},"click":function($event){return _vm.selectSequence(sequence.id)}}},[_vm._v(" "+_vm._s(sequence.name)+" ")])}),0),_c('div',{staticClass:"field"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.names.sequence),expression:"names.sequence"}],ref:"addSequenceInput",staticClass:"input",attrs:{"placeholder":"SQ01","type":"text"},domProps:{"value":(_vm.names.sequence)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.addSequence.apply(null, arguments)},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.names, "sequence", $event.target.value)}}}),_c('button',{class:{
                button: true,
                'is-success': true,
                'is-loading': _vm.loading.addSequence
              },attrs:{"disabled":!_vm.isAddSequenceAllowed},on:{"click":_vm.addSequence}},[_vm._v(" "+_vm._s(_vm.$t('main.add'))+" ")])])]),_c('div',{staticClass:"shot-column"},[_c('h2',{staticClass:"subtitle"},[_vm._v(_vm._s(_vm.$t('shots.title')))]),_c('div',{staticClass:"list"},_vm._l((_vm.displayedShots),function(shot){return _c('div',{key:shot.id,staticClass:"entity-line"},[_vm._v(" "+_vm._s(shot.name)+" ")])}),0),_c('div',{staticClass:"field"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.names.shot),expression:"names.shot"}],ref:"addShotInput",staticClass:"input",attrs:{"placeholder":"SH01","type":"text"},domProps:{"value":(_vm.names.shot)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.addShot.apply(null, arguments)},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.names, "shot", $event.target.value)}}})]),_c('div',{staticClass:"field"},[_c('button',{class:{
                button: true,
                'is-success': true,
                'is-loading': _vm.loading.addShot
              },attrs:{"disabled":!_vm.isAddShotAllowed || _vm.loading.addShot},on:{"click":_vm.addShot}},[_vm._v(" "+_vm._s(_vm.$t('main.add'))+" ")])])])]),_c('p',{staticClass:"has-text-right modal-footer"},[_c('button',{staticClass:"button is-link",on:{"click":function($event){return _vm.$emit('cancel')}}},[_vm._v(" "+_vm._s(_vm.$t("main.close"))+" ")])])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }