var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isLink)?_c('span',{staticClass:"avatar has-text-centered",style:({
    background: _vm.person.color,
    width: _vm.size +'px',
    height: _vm.size + 'px',
    'min-width': _vm.size +'px',
    'min-height': _vm.size + 'px',
    'font-size': _vm.person.has_avatar ? 0 : _vm.fontSize + 'px'
  })},[_c('router-link',{staticClass:"avatar-link",attrs:{"to":{
      name: 'person',
      params: {
        person_id: _vm.person.id
      }
    },"title":_vm.person.full_name}},[(_vm.person.has_avatar && _vm.noCache)?_c('img',{attrs:{"src":_vm.avatarPath}}):(_vm.person.has_avatar)?_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.avatarPath),expression:"avatarPath"}],key:_vm.avatarKey}):_vm._e(),(!_vm.person.has_avatar)?_c('span',[_vm._v(" "+_vm._s(_vm.initials)+" ")]):_vm._e()])],1):_c('span',{staticClass:"avatar has-text-centered",style:({
    background: _vm.person.color,
    width: _vm.size +'px',
    height: _vm.size + 'px',
    'font-size': _vm.fontSize + 'px'
  }),attrs:{"title":_vm.person.full_name}},[(_vm.person.has_avatar && _vm.noCache)?_c('img',{attrs:{"src":_vm.avatarPath}}):(_vm.person.has_avatar)?_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.avatarPath),expression:"avatarPath"}],key:_vm.avatarKey}):_c('span',[_vm._v(" "+_vm._s(_vm.initials)+" ")])])}
var staticRenderFns = []

export { render, staticRenderFns }