var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    field: _vm.withMargin,
    'field--narrow': _vm.narrow
  }},[(_vm.label.length > 0)?_c('label',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),_c('div',{staticClass:"status-combo",style:(_vm.comboStyles)},[_c('div',{staticClass:"flexrow",on:{"click":_vm.toggleStatusList}},[_c('div',{staticClass:"selected-status-line flexrow-item"},[(_vm.currentStatus)?_c('span',{staticClass:"tag",style:({
            background: _vm.backgroundColor(_vm.currentStatus),
            color: _vm.color(_vm.currentStatus)
          })},[_vm._v(" "+_vm._s(_vm.currentStatus.short_name)+" ")]):_vm._e()]),_c('chevron-down-icon',{class:{
        'down-icon': true,
        'flexrow-item': true,
        'white': _vm.colorOnly
      }})],1),(_vm.showStatusList)?_c('div',{ref:"select",class:{
        'select-input': true,
        'open-top': _vm.openTop
      }},_vm._l((_vm.taskStatusList),function(status){return _c('div',{key:status.id,staticClass:"status-line",on:{"click":function($event){return _vm.selectStatus(status)}}},[_c('span',{staticClass:"tag",style:({
            background: _vm.backgroundColor(status),
            color: _vm.color(status)
          })},[_vm._v(" "+_vm._s(status.short_name)+" ")])])}),0):_vm._e()]),_c('combobox-mask',{attrs:{"displayed":_vm.showStatusList},on:{"click":_vm.toggleStatusList}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }