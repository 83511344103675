var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
  'modal': true,
  'is-active': _vm.active
}},[_c('div',{staticClass:"modal-background",on:{"click":function($event){return _vm.$emit('cancel')}}}),_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"box content"},[_c('h2',{staticClass:"subtitle"},[_vm._v(_vm._s(_vm.title))]),_c('h1',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t("tasks.comment_image"))+" ")]),_c('div',{staticClass:"flexrow buttons"},[_c('file-upload',{ref:"file-field",staticClass:"flexrow-item",attrs:{"label":_vm.$t('main.select_file'),"accept":_vm.extensions,"multiple":true,"is-primary":false,"hide-file-names":""},on:{"fileselected":_vm.onFileSelected}}),(_vm.isMovie)?_c('p',{staticClass:"flexrow-item mt1"},[_vm._v(" "+_vm._s(_vm.$t('main.or'))+" ")]):_vm._e(),(_vm.isMovie)?_c('p',{staticClass:"flexrow-item"},[_c('button',{class:{
              button: true,
              'is-loading': _vm.isAnnotationLoading
            },on:{"click":function($event){return _vm.$emit('add-snapshots')}}},[_vm._v(" "+_vm._s(_vm.$t('main.attach_snapshots'))+" ")])]):_vm._e()],1),(_vm.forms.length > 0)?_c('h3',{staticClass:"subtitle has-text-centered"},[_vm._v(" "+_vm._s(_vm.$t('comments.selected_files'))+" ")]):_vm._e(),(_vm.forms.length > 0)?_c('p',{staticClass:"upload-previews"},[_vm._l((_vm.forms),function(form,i){return [_c('p',{key:'name-' + i,staticClass:"attachment-name"},[_vm._v(" "+_vm._s(form.get('file').name)+" "),_c('span',{on:{"click":function($event){return _vm.removeAttachment(form)}}},[_vm._v("x")])]),(_vm.isImage(form))?_c('img',{key:i,attrs:{"alt":"uploaded file","src":_vm.getURL(form)}}):(_vm.isVideo(form))?_c('video',{key:i,staticClass:"is-fullwidth",attrs:{"preload":"auto","controls":"","loop":"","muted":"","src":_vm.getURL(form)},domProps:{"muted":true}}):(_vm.isPdf(form))?_c('iframe',{key:i,staticClass:"is-fullwidth",attrs:{"frameborder":"0","src":_vm.getURL(form)}}):_vm._e(),_c('hr',{key:'separator-' + i})]})],2):_vm._e(),_c('p',{staticClass:"has-text-right mt2"},[_c('a',{class:{
            button: true,
            'is-primary': true,
            'is-loading': _vm.isLoading,
            'is-disabled': _vm.forms.length === 0
          },on:{"click":function($event){return _vm.confirm()}}},[_vm._v(" "+_vm._s(_vm.$t("tasks.confirm_attachments"))+" ")]),_c('button',{staticClass:"button is-link",on:{"click":function($event){return _vm.$emit('cancel')}}},[_vm._v(" "+_vm._s(_vm.$t("main.cancel"))+" ")])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }