import { render, staticRenderFns } from "./ComboboxSimple.vue?vue&type=template&id=ef5eac56&scoped=true&"
import script from "./ComboboxSimple.vue?vue&type=script&lang=js&"
export * from "./ComboboxSimple.vue?vue&type=script&lang=js&"
import style0 from "./ComboboxSimple.vue?vue&type=style&index=0&id=ef5eac56&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ef5eac56",
  null
  
)

export default component.exports